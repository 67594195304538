/* eslint-disable jsx-a11y/anchor-is-valid */
import AppContainer from "./AppContainer"
import Logo from '../assets/images/logo.svg'
import { useState } from "react"

export default function Header ({ scrollIndex = 0 }) {
  const [openMenu, setOpenMenu] = useState(false)

  function renderMobileMenu () {
    return (
      <div className={`head-mobile-navigation ${openMenu ? 'active' : ''}`}>
        <AppContainer>
          <ul>
            <li><a onClick={() => setOpenMenu(false)} href="#">Home</a></li>
            <li><a onClick={() => setOpenMenu(false)} href="#about">About Us</a></li>
            <li><a onClick={() => setOpenMenu(false)} href="#services">Services</a></li>
            <li><a onClick={() => setOpenMenu(false)} href="#experience">Experiences</a></li>
            <li><a onClick={() => setOpenMenu(false)} href="#team">Our Team</a></li>
            <li><a href="tel:021-22836016" className="btn btn-line-white">Contact Us</a></li>
          </ul>
        </AppContainer>
      </div>
    )
  }

  return (
    <>
      <header className={scrollIndex > 15 ? 'active' : ''}>
        <AppContainer>
          <div className="header-content">
            <div className="logo-wrap">
              <img src={Logo} alt="logo" />
            </div>
            <div className="menu-wrap">
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#about">About Us</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#experience">Experiences</a></li>
                <li><a href="#team">Our Team</a></li>
                <li><a href="tel:021-22836016" className="btn btn-line-white">Contact Us</a></li>
              </ul>
            </div>
            <div onClick={() => setOpenMenu(!openMenu)} className={`btn-mobile menu btn11 ${openMenu ? 'open' : ''}`} data-menu="11">
              <div className="icon-left"></div>
              <div className="icon-right"></div>
            </div>
          </div>

        </AppContainer>
      </header>
      {renderMobileMenu()}
    </>
  )
}