import { useEffect } from "react"

import AppContainer from "../components/AppContainer"
import serviceBanner1 from '../assets/images/services-banner-1.png'
import serviceBanner2 from '../assets/images/services-banner-2.png'
import serviceBanner3 from '../assets/images/services-banner-3.png'
import serviceBanner4 from '../assets/images/services-banner-4.png'
import serviceBanner5 from '../assets/images/services-banner-5.png'
import serviceBanner6 from '../assets/images/services-banner-6.png'

export default function Services ({ active, setRef }) {

  useEffect(() => {
    setRef(document.getElementById('services'))
  }, [setRef])

  return (
    <div id="services" className={`services-section ${active ? 'active' : ''}`}>
      <AppContainer>
          <div className="services-head">
            <div className="royal-label">Services</div>
            <div className="royal-title">Commitment to <span>Service</span></div>
            <div className="normal-text">RCT solutions are reliable-customer-driven and tailor -made in accordance with local laws.</div>
          </div>

          <div className="services-list">
            <div className="services-list-wrap w-40 wt-full">
              <div className="services-item overlay w-50 wm-full">
                <div className="services-item-inner">
                  <img src={serviceBanner1} alt="services" />
                  <div className="text">Strategic Consulting</div>
                </div>
              </div>
              <div className="services-item w-50 wm-full">
                <div className="services-item-inner">
                  <img src={serviceBanner2} alt="services" />
                  <div className="text">Performance</div>
                </div>
              </div>
              <div className="services-item overlay w-full">
                <div className="services-item-inner">
                  <img src={serviceBanner5} alt="services" />
                  <div className="text">Best Quality</div>
                </div>
              </div>
            </div>
            <div className="services-list-wrap w-40 wt-60 wm-full">
              <div className="services-item overlay w-full">
                <div className="services-item-inner">
                  <img src={serviceBanner3} alt="services" />
                  <div className="text topleft">Trust - Confidentiality</div>
                </div>
              </div>
            </div>
            <div className="services-list-wrap w-20 wt-40 wm-full">
              <div className="services-item overlay w-full">
                <div className="services-item-inner">
                  <img src={serviceBanner4} alt="services" />
                  <div className="text">After Sales Support</div>
                </div>
              </div>
              <div className="services-item w-full">
                <div className="services-item-inner">
                  <img src={serviceBanner6} alt="services" />
                  <div className="text">Equipment</div>
                </div>
              </div>
            </div>
          </div>
      </AppContainer>
    </div>
  )
}