import { useEffect } from "react"

import AppContainer from "../components/AppContainer"
import teamBanner1 from '../assets/images/team-banner-1.png'
import teamBanner2 from '../assets/images/team-banner-2.png'

export default function Team ({ active, setRef }) {

  useEffect(() => {
    setRef(document.getElementById('team'))
  }, [setRef])

  return (
    <div id="team" className={`team-section ${active ? 'active' : ''}`}>
      <AppContainer>
        <div className="team-section-wrap">
          <div className="team-image">
            <img src={teamBanner1} alt="banner1" />
            <img src={teamBanner2} alt="banenr2" />
          </div>
          <div className="team-content ">
            <div className="royal-label">Our Team</div>
            <div className="royal-title">Our team consist of <span>highly experienced team</span> that focus on <span>synergy, complimentary, and teamwork</span>.</div>
            <div className="normal-text">
              RCT is managed by an experienced, dynamic and multicultural group of people with diversified backgrounds in Defense, IT, Telecommunication, Engineering, Oil and Gas, Business Management, International Organizations, etc. 
              <br /><br />
              We team-up to deliver a dynamic combination of management, expertise and execution to our partners. We firmly believe that profit and success do not come without trust, and we value long term relationships.
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  )
}