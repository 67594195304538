import { useEffect } from "react"

import AppContainer from "../components/AppContainer"

import globeImage from '../assets/images/about-globe.png'
import exp1 from '../assets/images/about-exp-1.png'
import exp2 from '../assets/images/about-exp-2.png'
import exp3 from '../assets/images/about-exp-3.png'
import exp4 from '../assets/images/about-exp-4.png'
import exp5 from '../assets/images/about-exp-5.png'
import exp6 from '../assets/images/about-exp-6.png'
import AboutExpertise from "../components/AboutExpertise"

export default function About ({ active, exptActive, setRef, setExptRef }) {

  useEffect(() => {
    setRef(document.getElementById('about'))
    setExptRef(document.getElementById('about-expt'))
  }, [setRef, setExptRef])

  return (
    <div id="about" className={`about-section ${active ? 'active' : ''} ${exptActive ? 'active-expt' : ''}`}>
      <AppContainer>
          <div className="about-info">
            <div className="info-image">
              <img src={globeImage} alt="globe" />
            </div>
            <div className="info-content">
              <div className="royal-label">About Us</div>
              <div className="royal-title">About <span>Royal Cemerlang Teknologi</span></div>
              <p>
                The company creation resulted from a synergy of long-time business partners who decided to team up to offer their customers a more comprehensive solution to their needs.
                <br /><br />
                RCT is a good team company for operating as official representative/distributor of manufacturers and brands that are key players in their industries, ensuring RCT solutions is always provide its customers the best solution.
                <br /><br />
                We aim to offer comprehensive, serviced-based solutions in high risk and complex environments across the Indonesian Archipelago, but as well beyond the Indonesian borders, in other South East Asian nations. 
                <br /><br />
                We focus exclusively on our customers’ requirements and the need to provide them with responsiveness, effectiveness, unquestionable integrity and a fully commited after-sales team.
              </p>
            </div>
          </div>
          <div id="about-expt" className="about-expertise">
            <div className="label">Among a wide range of sectors, we use our expertise in fields such as:</div>
            <div className="expertise-list">
              <AboutExpertise
                src={exp1}
                text="Cyber Intelligence & Security"
              />
              <AboutExpertise
                src={exp2}
                text="Intelligence & Data Collection"
              />
              <AboutExpertise
                src={exp3}
                text="Surveillance & Reconnaissance"
              />
              <AboutExpertise
                src={exp4}
                text="Advanced Encrypted Communication Systems"
              />
              <AboutExpertise
                src={exp5}
                text="Engineering"
              />
              <AboutExpertise
                src={exp6}
                text="Information Technology"
              />
            </div>
          </div>
      </AppContainer>
    </div>
  )
}