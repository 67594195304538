
import React from 'react'

export default function AboutExpertise ({ src = false, text = '' }) {
  return (
    <div className="about-expertise-box">
      <img src={src} alt="expertise" />
      <div className="text">{ text }</div>
    </div>
  )
}