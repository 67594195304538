import { useEffect, useState, useRef } from "react"

import AppContainer from './components/AppContainer';
import Header from './components/Header';
import Footer from './components/Footer';

import About from './sections/About';
import Experience from './sections/Experience';
import HeroBanner from './sections/HeroBanner';
import Services from './sections/Services';
import Team from './sections/Team';

import './style/index.scss';

function App() {
  const [heroPos, setHeroPos] = useState(0)
  const [aboutPos, setAboutPos] = useState(0)
  const [aboutExptPos, setAboutExptPos] = useState(0)
  const [servicesPos, setServicesPos] = useState(0)
  const [experiencePos, setExperiencePos] = useState(0)
  const [teamPos, setTeamPos] = useState(0)

  const [heroActive, setHeroActive] = useState(false)
  const [aboutActive, setAboutActive] = useState(false)
  const [aboutExptActive, setAboutExptActive] = useState(false)
  const [servicesActive, setServicesActive] = useState(false)
  const [experienceActive, setExperienceActive] = useState(false)
  const [teamActive, setTeamActive] = useState(false)
  const [currScroll, setCurrScroll] = useState(0)
  const [videoLoaded, setVideoLoaded] = useState(false)

  const heroRef = useRef(null)
  const aboutRef = useRef(null)
  const aboutExptRef = useRef(null)
  const servicesRef = useRef(null)
  const experienceRef = useRef(null)
  const teamRef = useRef(null)

  useEffect(() => {
    if (videoLoaded) {
      initPage()
    }
  }, [videoLoaded])

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll)
    return () => {
      window.removeEventListener('scroll', listenToScroll)
    }
  })

  function initPage () {
    const hero = heroRef.current
    const about = aboutRef.current
    const aboutExpt = aboutExptRef.current
    const services = servicesRef.current
    const experience = experienceRef.current
    const team = teamRef.current
    const verticalWindowHalf = Math.floor(window.innerHeight / 3) + 50

    console.log('about: ', about)
    console.log('aboutExpt: ', aboutExpt)

    if (hero && about && services && experience && team) {
      setHeroPos(hero.offsetTop - verticalWindowHalf)
      setAboutPos(about.offsetTop - verticalWindowHalf)
      setAboutExptPos(aboutExpt.offsetTop - verticalWindowHalf)
      setServicesPos(services.offsetTop - verticalWindowHalf)
      setExperiencePos(experience.offsetTop - verticalWindowHalf)
      setTeamPos(team.offsetTop - verticalWindowHalf)

      setTimeout(() => {
        setHeroActive(true)
      }, 5000);
    }
  }

  function listenToScroll (e) {
    const documentScrollVal = document.documentElement.scrollTop || document.body.scrollTop
    setCurrScroll(documentScrollVal)

    if (documentScrollVal >= aboutPos) {
      setAboutActive(true)
    }
    if (documentScrollVal >= aboutExptPos) {
      setAboutExptActive(true)
    }
    if (documentScrollVal >= servicesPos) {
      setServicesActive(true)
    }
    if (documentScrollVal >= experiencePos) {
      setExperienceActive(true)
    }
    if (documentScrollVal >= teamPos) {
      setTeamActive(true)
    }
  }

  function setAboutRef (element) {
    aboutRef.current = element
  }
  function setAboutExptRef (element) {
    aboutExptRef.current = element
  }
  function setHeroRef (element) {
    heroRef.current = element
  }
  function setServicesRef (element) {
    servicesRef.current = element
  }
  function setExperienceRef (element) {
    experienceRef.current = element
  }
  function setTeamRef (element) {
    teamRef.current = element
  }

  function onVideoLoad () {
    setVideoLoaded(true)
  }

  return (
    <div className={`app app-animate ${heroActive ? 'active' : ''}`}>
      <Header scrollIndex={currScroll} />
      <HeroBanner active={heroActive} setRef={setHeroRef} onVideoLoad={onVideoLoad} />
      <About active={aboutActive} exptActive={aboutExptActive} setRef={setAboutRef} setExptRef={setAboutExptRef}/>
      <Services active={servicesActive} setRef={setServicesRef} />
      <Experience active={experienceActive} setRef={setExperienceRef} />
      <Team active={teamActive} setRef={setTeamRef} />

      <AppContainer>
        Contact Us
      </AppContainer>
      <Footer />
    </div>
  );
}

export default App;
