import AppContainer from "./AppContainer"
import Logo from '../assets/images/logo.svg'

export default function Footer () {
  return (
    <footer className="footer">
      <AppContainer>
        <div className="footer-content">
          <div className="mini-about">
            <img src={Logo} alt="logo" />
            <div className="content">
              <b>Royal Cemerlang Teknologi</b> is a good team company for operating as official representative/distributor of manufacturers and brands that are key players in their industries, ensuring RCT solutions is always provide its customers the best solution.
            </div>
          </div>
          <div className="mini-menu">
            <ul>
              <li className="title">Menu</li>
              <li className="list">
                <a href="#">Home</a>
              </li>
              <li className="list">
                <a href="#about">About</a>
              </li>
              <li className="list">
                <a href="#services">Services</a>
              </li>
              <li className="list">
                <a href="#experience">Experience</a>
              </li>
              <li className="list">
                <a href="tel:021-22836016">Contact</a>
              </li>
            </ul>
          </div>
          <div className="mini-menu">
            <ul>
              <li className="title">Social Networks</li>
              <li className="list">
                <a href="mailto:info@royalcemerlang.com">Email</a>
              </li>
              {/* <li className="list">
                <a href="#">Instagram</a>
              </li>
              <li className="list">
                <a href="#">Tiktok</a>
              </li>
              <li className="list">
                <a href="#">Twitter</a>
              </li>
              <li className="list">
                <a href="#">LinkedIn</a>
              </li>
              <li className="list">
                <a href="#">Whatsapp</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="text">
            Copyright © 2023. All right reserved.
          </div>
          <div className="text">
            Indonesia
          </div>
        </div>
      </AppContainer>
    </footer>
  )
}