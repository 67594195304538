import { useEffect, useRef } from "react"

import AppContainer from "../components/AppContainer"
import HeadSvg from "../components/HeadSvg"
import arrowIcon from '../assets/images/arrow.svg'
import HeroBackground from '../assets/videos/hero-background.mp4'
import HeroImage from '../assets/images/hero-banner-head.png'

export default function Header ({ active, setRef, onVideoLoad }) {
  const videoDom = useRef(null)

  useEffect(() => {
    videoDom.current.play()
    setRef(document.getElementById('hero'))
  }, [setRef])

  function gotoAboutSection () {
    window.location.href = '#about'
  }

  return (
    <div id="hero" className={`hero-banner ${active ? 'active' : ''}`}>
      <video
        ref={videoDom}
        className="hero-banner-background"
        autoPlay loop muted
        src={HeroBackground}
        onLoadedData={onVideoLoad}
        playsInline
        webkit-playsInline
      />
      
      <AppContainer>
        <div className="hero-content">

          <div className="banner-head">
            <HeadSvg className="banner-svg" />
            <img src={HeroImage} className="banner-img" alt="hero" />
          </div>
          <div className="banner-content">
            <div className="royal-label">Announcing our Commitment</div>
            <div className="royal-title">
              Royal Cemerlang: We Offer <br /><span>State-of-the Art Technology</span>
            </div>
          </div>
          <div className="scroll-down-wrap" onClick={gotoAboutSection}>
            <img src={arrowIcon} alt="arrow" />
          </div>
        </div>
      </AppContainer>
    </div>
  )
}