import { useEffect } from "react"

import AppContainer from "../components/AppContainer"
import ExpBox from "../components/ExpBox"

import cyberExp from '../assets/images/exp-cyber.svg'
import engineerExp from '../assets/images/exp-engineer.svg'
import reconExp from '../assets/images/exp-recon.svg'
import otherExp from '../assets/images/exp-other.svg'
import itExp from '../assets/images/exp-it.svg'

export default function Experience ({ active, setRef }) {
  useEffect(() => {
    setRef(document.getElementById('experience'))
  }, [setRef])

  return (
    <div id="experience" className={`experience-section ${active ? 'active' : ''}`}>
      <AppContainer>
        <div className="experience-inner">
          <div className="experience-head">
            <div className="royal-label">Experience</div>
            <div className="royal-title">Project <span>Experience</span></div>
          </div>

          <div className="experience-list">
            <div className="w-40">
              <ExpBox
                src={engineerExp}
                text="Engineering"
                list={[
                  'Robotics and autonomous system.',
                  'Drone and UAV (Unmanned Aerial Vehicle).',
                  'Training Simulators.',
                  'Tactical Command Center.',
                  'Etc.',
                ]}
              />
              <ExpBox
                src={itExp}
                text="Information Technology"
                list={[
                  'Command Center',
                  'Integrated Secure Prison System',
                  'Biometric and Face Recognition System',
                  'Etc.',
                ]}
              />
              <ExpBox
                src={reconExp}
                text="Surveillance & Reconnaisance"
                list={[
                  'Nano UAV',
                  'Night Vision Goggles',
                  'Thermal Imaging',
                  'Sniper Detection',
                  'Wireless Surveillance Distributing System',
                  'Etc.',
                ]}
              />
            </div>
            <div className="w-40">
              <ExpBox
                src={cyberExp}
                text="Cyber"
                list={[
                  'Direction Finder GSM and UMTS (Mobile, Portable and Backpack)',
                  'Location Tracking System',
                  'Non-Intrusion Media Monitoring Location Tracking',
                  'Cyber Defense',
                  'SOC (Security Operations Center)',
                  'Profiling System',
                  'Etc.',
                ]}
              />
              <ExpBox
                src={otherExp}
                text="Others"
                list={[
                  'Tactical Communication Systems',
                  'Interoperable Secured Communication System',
                  'Medium Size Hovercraft for Emergency Responders & Disasters Relief',
                  'Tactical Power Manangement System.',
                  'Etc.',
                ]}
              />
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  )
}