
import React from 'react'

export default function ExpBox ({ src = '', text = '', list = [] }) {
  return (
    <div className="experience-box">
      <img src={src} alt="expertise" />
      <div className="text">{ text }</div>
      <ul>
        {
          list.map((item, index) => (
            <li key={index}>{ item }</li>
          ))
        }
      </ul>
    </div>
  )
}